import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../assets/css/Carousel.css";
import { Link } from 'react-router-dom';

const Carousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.2, // Show 100% of the first slide and 20% of the next one
    slidesToScroll: 1,
    centerMode: true, // Enable center mode for better alignment
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false, // Disable center mode on smaller screens
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  return (
    <div className="carousel-container mb-5">
      <Slider {...settings} className='mb-5'>
        <div className="carousel-item">
          <div className="banner-blog-card">
            <img
              src="/images/blog/blog-home-banner_1.png"
              className="card-img-top"
              alt="Blog Image"
            />
            <div className="blog-detail-box">
              <div className="d-flex align-items-center">
                <span className="badge">Technology</span>
                <span className="text-muted small">5 min read</span>
              </div>
              <h5 className="card-title mt-2"><Link to='/blog/Actionable-Tips-for-Managing'>Actionable Tips for Managing Remote Teams</Link></h5>
              <p className="card-text bloglandingcsubtc">
              Articles focused on helping teams stay organized, streamline their workflows, and boost...
              </p>
              <span class="arrow-icon">
              <Link to='/blog/productivity-task-management'>
               <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z" fill="white" />
                </svg>
                
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="banner-blog-card">
            <img
              src="images/blog/blog-home-banner-2.png"
              className="card-img-top"
              alt="Blog Image"
            />
            <div className="blog-detail-box">
              <div className="d-flex align-items-center">
                <span className="badge">Technology</span>
                <span className="text-muted small">5 min read</span>
              </div>
              <h5 className="card-title mt-2"><Link to='/blog/productivity-task-management'>How AI Assistants are Revolutionizing Task Management</Link></h5>
              <p className="card-text bloglandingcsubtc">
              Explore how artificial intelligence is reshaping modern workplaces, from automating tasks
            </p>
              <span class="arrow-icon">
              <Link to='/blog/productivity-task-management'> <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z" fill="white" />
                </svg></Link>
              </span>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="banner-blog-card">
            <img
              src="images/blog/blog-home-banner-3.png"
              className="card-img-top"
              alt="Blog Image"
            />
            <div className="blog-detail-box">
              <div className="d-flex align-items-center">
                <span className="badge">Technology</span>
                <span className="text-muted small">5 min read</span>
              </div>
              <h5 className="card-title mt-2"><Link to='/blog/employee-engagemente-culture'>
              The Role of Data in Boosting Employee Engagement</Link></h5>
              <p className="card-text bloglandingcsubtc">
              Tips, insights, and case studies on how to build a happier, more engaged workforce
               </p>
              <span class="arrow-icon">
              <Link to='/blog/employee-engagemente-culture'> <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
                  <path d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z" fill="white" />
                </svg></Link>
              </span>
            </div>
          </div>
        </div>
        {/* <div className="carousel-item">
          <div className="banner-blog-card">
            <img
              src="images/blog/newblog4.png"
              className="card-img-top"
              alt="Blog Image"
            />
            <div className="blog-detail-box">
              <div className="d-flex align-items-center">
                <span className="badge">Technology</span>
                <span className="text-muted small">5 min read</span>
              </div>
              <h5 className="card-title mt-2"><a href='#'>
              Leadership & Management</a></h5>
              <p className="card-text">
              Resources for team leaders and managers looking to improve team performance, communication, and engagement using data-driven strategies.
              </p>
              <span class="arrow-icon">
              <Link to='/blog-details'> <svg width="14" height="14" viewBox="0 0 18 18" fill="none">
                  <path d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z" fill="white" />
                </svg></Link>
              </span>
            </div>
          </div>
        </div> */}


      </Slider>
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,

      }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        top: '50%',
        left: '0',
        zIndex: '1',
        display: 'block',
        transform: 'translateY(-50%)'
      }}
      onClick={onClick}
    />
  );
};

export default Carousel;
