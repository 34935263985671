import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router-dom';
import Onboarding from './Onboarding';
import Users from './Users';
import Tasks from './Tasks';
import Teams from './Teams';
import Meetings from './Meetings';
import ManageYourAccount from './ManageYourAccount';
import Kwikbot from './Kwikbot';
import Integrations from './Integrations';
import FAQs from './FAQs';
import Analytics from './Analytics';






function HelpDetails() {
    const [activeSection, setActiveSection] = useState('Onboarding');
    const [activeTitle, setactiveTitle] = useState('Onboarding');
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])


    const sections = [
        { id: 'Onboarding', title: 'Onboarding' },
        { id: 'Users', title: 'Users' },
        { id: 'Teams', title: 'Teams' },
        { id: 'Tasks', title: 'Tasks' },
        { id: 'Meetings', title: 'Meetings' },
        { id: 'ManageYourAccount', title: 'Manage Your Account' },
        { id: 'Integrations', title: 'Integrations' },
        { id: 'Analytics', title: 'Analytics' },
        { id: 'Kwikbot', title: 'Kwikbot' },
       

    ];

    const renderSectionContent = (id, title) => {
        setActiveSection(id);
        setactiveTitle(title);

    }
    const renderContent = () => {
        switch (activeSection) {
            case 'Onboarding':
                return <Onboarding activeSection={activeSection} />;
            case 'Users':
                return <Users activeSection={activeSection} />;
            case 'Tasks':
                return <Tasks activeSection={activeSection} />;
            case 'Meetings':
                return <Meetings activeSection={activeSection} />;
            case 'ManageYourAccount':
                return <ManageYourAccount activeSection={activeSection} />;
            case 'Kwikbot':
                return <Kwikbot activeSection={activeSection} />;
            case 'Integrations':
                return <Integrations activeSection={activeSection} />;
            case 'Analytics':
                return <Analytics activeSection={activeSection} />;
            case 'Teams':
                return <Teams activeSection={activeSection} />;
          
            // Add other cases here for additional sections
            default:
                return null;
        }
    };

    useEffect(() => {

        if (location.state && location.state.activeSection) {
            setActiveSection(location.state.activeSection);
            setactiveTitle(location.state.activeTitle)

        } else {
            setActiveSection('Onboarding');
        }
    }, [location.state]);
    return (
        <div className="publicpage">
            <Header />
            <section>
                <div className="innercontainer">
                    <div className="blog-top-section">
                        <div className="row">
                            <div className="col-md-12">

                                <div className="page_heading publicpageolul">
                                    <h3 className="h1ai">{activeTitle}</h3>
                                    <div>
                                        <li className="breadcrumb-item">
                                            <Link to="/help-center">Help Center <span className="mr-2"> {">"}</span></Link>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <a href="#"> {activeTitle} </a>
                                        </li>
                                    </div>

                                    <p className="pai">
                                        Learn the best ways to set up your work in Uniteam.
                                    </p>
                                    <div className="terms-container">
                                        <div className="terms-wrapper">
                                            {/* Left Sidebar */}
                                            <aside className="terms-sidebar">
                                                <ol className='custom-numbered-list'>
                                                    {sections.map((section) => (
                                                        <li
                                                            key={section.id}
                                                            className={activeSection === section.id ? 'active' : ''}
                                                            onClick={() => renderSectionContent(section.id, section.title)}
                                                        >
                                                            {section.title}
                                                        </li>
                                                    ))}
                                                </ol>
                                            </aside>

                                            {/* Right Content Section */}
                                            <div className="terms-content">
                                                {renderContent()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <NewFooter />
        </div>
    );
}

export default HelpDetails;
