import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Teams() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Managing Teams in Uniteam</h3>
                <p className='pai'>
                    When you first sign up on Uniteam as the Admin, you’ll be asked to select a few teams during onboarding. These teams will automatically appear in the Teams section. But don’t worry—you can always create new teams later! Here’s a friendly guide to help you manage and add more teams as your organization grows.

                </p>
                <p className='steps'>Step 1: Open the Teams Section

                </p>
                <p className='pai'>
                    Once you're logged into Uniteam, head to the left-hand menu and click on Teams. Here, you'll find the teams you selected during onboarding already listed.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team1.png" alt="" /> 
                    </div>
                </div>
                <p className='steps'>Step 2: View and Manage Existing Teams


                </p>
                <p className='pai'>
                    The teams you set up during onboarding are displayed in the Teams section. You can click on any team to view or manage its details, add members, or adjust roles.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team2.png" alt="" /> 
                    </div>
                </div>
                <p className='steps'>Step 3: Create a New Team

                </p>
                <p className='pai'>
                    To add a new team, click the + Create Team button at the top right corner of the Teams page. This is where you can organize additional teams for departments, projects, or any group needing collaboration.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team3.png" alt="" /> 
                    </div>
                </div>
                <p className='steps'>Step 4: Name Your Team

                </p>
                <p className='pai'>
                    A popup will appear asking you to provide a name for the new team. Choose something that makes sense for the group, like Marketing Team or Development Team. After naming it, click Next.

                </p>

                <p className='steps'>Step 5: Add Members to Your New Team



                </p>
                <p className='pai'>
                    After naming the team, the next popup will prompt you to Add Members. You can enter email addresses to invite users, or select members from your organization if they’ve already signed up.
                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team4.png" alt="" /> 
                    </div>
                </div>

                <p className='steps'>Step 6: Assign Roles

                </p>
                <p className='pai'>
                    As the Admin, you can assign roles to your team members. To do this, click on the three dots on the team member’s card. From here, you can easily designate someone as the Team Leader to help manage the team, or keep them as a Team Member. This ensures responsibilities are distributed according to each member’s role in the team.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team5.png" alt="" /> 
                    </div>
                </div>
                <p className='steps'>Step 7: Manage and Edit Teams




                        </p>
                <p className='pai'>
                    To make changes to your teams, head back to the Teams section. To rename a team, click on the Gear icon of the currently highlighted team. To edit members or update roles, click the three dots next to a team member's name. This gives you flexibility to keep everything organized as your teams grow.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/team6.png" alt="" /> 
                    </div>
                </div>
                <p className='pai'>
                    And that’s it! Whether you're managing teams created during onboarding or adding new ones later, Uniteam makes it simple to keep your teams organized. With everything set, collaboration becomes smooth and efficient across your organization.

                </p>
            </div>
        </>
    );

}

export default Teams;
