import React, { useEffect } from 'react'
import ContactUsSection from './components/ContactUsSection'
import Footer from './components/Footer'
import Header from './components/Header'
import NewFooter from './components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";
import FrequentlyAskQues from "./components/FrequentlyAskQues";

const ContactUs = () => {
   const [formData, setFormData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        department: "",
        message: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(formData)
    }


    useEffect(() => {
        window.scrollTo(0, 0)
        localStorage.setItem("scrollTo", "")
    }, [])
    return (
        <div className="publicpage">

            <Header />



            {/* Top Banner */}
            <div className='contp' >

          
            <section className="help-banner-container">
                <div className='banner-container-detals'>
                    <h1>Get in Touch with Us</h1>
                    <div className="">
                        <p className='banner-text'>Whether you have questions, need support, or want to explore partnerships, we're here to help. Reach out to our team, and we’ll get back to you as soon as possible.</p>
                    </div>


                </div>
            </section>
            </div>
            <div className='innercontainer'>

                <section class="impowering-section mt-100">
                    <div class="">
                        <h2>How Can We Assist You?</h2>
                        <p className='comon-para'>If you have any questions or need more information about Uniteam, feel free to reach out.<br /> We’re happy to help!
                        </p>

                    </div>
                    <div className="container-fluid">
                        <div className="row mt-40">
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>General Inquiries </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        If you have any questions or need more information about Uniteam, feel free to reach out. We’re happy to help!
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Customer Support </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        Our dedicated support team is here to ensure you get the most out of Uniteam. Let us know if you need assistance with the platform. </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="doc-card justify-content-center align-items-start">
                                    <div className="">
                                        <img
                                            src="images/home/qans-icon.svg"
                                            className="img-fluid helpqa-img"
                                            alt="teamwork and collaboration"
                                        />
                                        <div className="public-h3">
                                            <h3>Partnership Opportunities </h3>
                                        </div>
                                    </div>
                                    <div className="card-desc">
                                        <p>
                                        Interested in partnering with Uniteam? We’re excited to explore potential collaborations that can benefit both parties.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                {/* <!-- Customer First Approach --> */}
                <section class="customer-approach mt-100">
                    <div class="row my-4">
                        <div class="col-md-6 col-sm-12">
                            <h2 class="mb-40"> Get in touch <br />with us</h2>

                            <div class="form-container">
                                <div className='row '>
                                    <div className='col-md-6'>
                                        <input id="name" required="" type="text" class="form-control" maxlength="50" placeholder="First name"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        />
                                    </div>

                                    <div className='col-md-6'>
                                        <input id="name" required="" type="text" class="form-control" maxlength="50" 
                                        placeholder="Last name"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}

                                         />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <input id="name" required="" type="text" class="form-control" maxlength="50" 
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                         />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <input id="name" required="" type="text" class="form-control" maxlength="50" 
                                        placeholder="Phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}

                                         />
                                    </div>

                                    <div className='col-md-6'>
                                        <select class="form-select mb-10"
                                        name="department"
                                        value={formData.department}
                                        onChange={handleChange}

                                         required>
                                            <option selected disabled>Department</option>
                                            <option value="sales">Sales</option>
                                            <option value="support">Support</option>
                                            <option value="general">General Inquiry</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row '>
                                    <div className='col-md-12'>
                                        <textarea class="form-control textarea" placeholder="Write us a message..." required></textarea>

                                    </div>


                                </div>
                                <div className='row mb-10'>
                                    <div className='col-md-12 mt-10 text-right'>
                                        <button type="submit" class="btn btn-contact-save" 
                                        onClick={handleSubmit}
                                        >Submit</button>
                                    </div>


                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">

                            <img src="../images/home/contact-form-img.png" class="img-fluid img-wid100 " alt="Customer handshake" />

                        </div>

                    </div>


                </section>

                <section className="contact-info-section mt-131 ">
                    <h4 className=''>Other Ways to Reach Us  </h4>
                    <div className="row ">
                        <div className="col-md-4 col-sm-6">

                            <img src="../images/home/contact-img2.png" class="img-fluid img-wid100 mb-40 " alt="Customer handshake" />
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className='row mb-40 '>
                                <div className='col-md-6'>
                                    <div className='contact-info'>
                                        <p className="emailinfo"> info@uniteam.ai</p>
                                        <p className="emailfor">General Inquiries</p>
                                    </div>
                                    <div className='contact-info'>
                                        <p className="emailinfo">partners@uniteam.ai</p>
                                        <p className="emailfor">Partnerships</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='contact-info'>
                                        <p className="emailinfo">support@uniteam.ai</p>
                                        <p className="emailfor">Customer Support</p>
                                    </div>
                                    <div className='contact-info'>
                                        <p className="emailinfo"> press@uniteam.ai</p>
                                        <p className="emailfor">Media</p>
                                    </div>
                                </div>

                            </div>

                            <div className='row mb-40 '>
                                <div className='col-md-4'>
                                    <div className='contact-info'>
                                        <img src='images/icons/phone-call.svg' className='info-icon' />
                                        <p>+91 8204079333</p>
                                        <p>+91 8204079333</p>
                                    </div>
                                    <div class="infovl"></div>
                                </div>
                                <div className='col-md-4'>

                                    <div className='contact-info'>
                                        <img src='images/icons/business-time.svg' className='info-icon' />
                                        <p className=""> Monday - Friday,</p>
                                        <p> 9 AM to 6 PM IST</p>
                                    </div>
                                    <div class="infovl"></div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='contact-info'>
                                        <img src='images/icons/business-address.svg' className='info-icon' />
                                        <p className=""> Uniteam Technologies Pvt. Ltd. Gurugram, Haryana, India</p>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>

                </section>
                <section className="mt-131 ">
                    <div className="row ">
                        <div className="col-md-6 col-sm-6">
                            <h1 className="h1ai">Need help?</h1>
                            <p className="pai ">
                                Don't worry, we got you. Here are some answers for your
                                questions.
                            </p>
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <FrequentlyAskQues />
                        </div>
                    </div>

                </section>

            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default ContactUs