import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Header = ({ featureRef, pricingRef }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleNavCollapse = () => {
    setIsNavCollapsed(!isNavCollapsed);
    setActiveDropdown(null); // Close dropdown when toggling navbar
  };

  const handleDropdownHover = (dropdownName) => {
    setActiveDropdown(dropdownName); // Open the hovered dropdown
  };

  const handleDropdownLeave = () => {
    setActiveDropdown(null); // Close dropdown on mouse leave
  };

  const toggleDropdownClick = (dropdownName) => {
    if (activeDropdown === dropdownName) {
      setActiveDropdown(null); // Close if already open
    } else {
      setActiveDropdown(dropdownName); // Open the selected dropdown
    }
  };

  const closeAllDropdowns = () => {
    setActiveDropdown(null);
  };

  const isActiveSubMenu = (path) => location.pathname === path;

  useEffect(() => {
    // Close dropdown on route change
    closeAllDropdowns();
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar navbar-default navbar-fixed-top">
        <div className="innercontainer d-flex-space-between align-items-center">
          <div className="navbar-header">
            <button
              className={`navbar-toggle collapsed ${
                isNavCollapsed ? "" : "open"
              }`}
              onClick={toggleNavCollapse}
            >
              <span className="sr-only">Toggle navigation</span>
              <span
                className={`icon-bar ${isNavCollapsed ? "" : "hidden"}`}
              ></span>
              <span
                className={`icon-bar ${isNavCollapsed ? "" : "hidden"}`}
              ></span>
              <span
                className={`icon-bar ${isNavCollapsed ? "" : "hidden"}`}
              ></span>
              <span className={`icon-cross ${isNavCollapsed ? "hidden" : ""}`}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </span>
            </button>

            <a
              className="navbar-brand d-flex"
              onClick={() => localStorage.setItem("scrollTo", "")}
            >
              <img
                alt="Uniteam Logo"
                onClick={() => navigate("/")}
                src="/images/icons-v2/uniteam-Logo-new.svg"
                className="unilogo"
              />
            </a>
          </div>

          <div
            className={`navbar-collapse ${isNavCollapsed ? "collapse" : ""}`}
          >
            <ul className="nav navbar-nav navbar-right rightnav">
              {/* Features Submenu */}
              <li
                className={`header-nav-item ${
                  activeDropdown === "features" ? "open" : ""
                }`}
                onMouseEnter={() => handleDropdownHover("features")}
                onMouseLeave={handleDropdownLeave}
              >
                <Link
                  className={`header-nav-link text-black ${
                    location.pathname.startsWith("/feature")
                      ? "active-link"
                      : ""
                  }`}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdownClick("features");
                  }}
                >
                  Features
                  <i
                    className={`fa ${
                      activeDropdown === "features"
                        ? "fa-angle-up"
                        : "fa-angle-down"
                    }`}
                    style={{ marginLeft: "5px", fontSize: "10px" }}
                  ></i>
                </Link>

                {activeDropdown === "features" && (
                  <ul className="header-dropdown-menu header-dropdown-show">
                    <li className="header-dropdown-item">
                      <Link
                        to="/feature/task"
                        className={
                          isActiveSubMenu("/feature/task") ? "active-link" : ""
                        }
                        onClick={() => {
                          setIsNavCollapsed(true);
                          closeAllDropdowns();
                        }}
                      >
                        Tasks
                      </Link>
                    </li>
                    <li className="header-dropdown-item">
                      <Link
                        to="/feature/ai-assistant"
                        className={
                          isActiveSubMenu("/feature/ai-assistant")
                            ? "active-link"
                            : ""
                        }
                        onClick={() => {
                          setIsNavCollapsed(true);
                          closeAllDropdowns();
                        }}
                      >
                        AI Assistant
                      </Link>
                    </li>
                    <li className="header-dropdown-item">
                      <Link
                        to="/feature/meeting"
                        className={
                          isActiveSubMenu("/feature/meeting")
                            ? "active-link"
                            : ""
                        }
                        onClick={() => {
                          setIsNavCollapsed(true);
                          closeAllDropdowns();
                        }}
                      >
                        Meetings
                      </Link>
                    </li>
                    <li className="header-dropdown-item">
                      <Link
                        to="/feature/performance-analytics"
                        className={
                          isActiveSubMenu("/feature/performance-analytics")
                            ? "active-link"
                            : ""
                        }
                        onClick={() => {
                          setIsNavCollapsed(true);
                          closeAllDropdowns();
                        }}
                      >
                        Performance Analytics
                      </Link>
                    </li>
                  </ul>
                )}
              </li>

              {/* Other Menu Items */}
              <li className="nav-fontlink nav-item">
                <Link
                  className={`text-black ${
                    location.pathname === "/about-us" ? "active-link" : ""
                  }`}
                  to="/about-us"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-fontlink nav-item">
                <Link
                  className={`text-black ${
                    location.pathname === "/blogs" ? "active-link" : ""
                  }`}
                  to="/blogs"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  Blogs
                </Link>
              </li>
              <li className="nav-fontlink nav-item show-in-mob">
                <Link
                  className="text-black"
                  to="https://app.uniteam.ai/"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  Sign In
                </Link>
              </li>
              <li>
                <Link
                  to="https://app.uniteam.ai/register"
                  target="_blank"
                  className="btn btn-primary mrl navlink w-106 text-white show-in-mob"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  <span>Try it free</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="hide-in-mob">
            <div className="d-flex align-items-center w-100">
              <div className="me-2">
                <Link
                  className="text-black"
                  to="https://app.uniteam.ai/"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  Sign In
                </Link>
              </div>
              <div>
                <Link
                  to="https://app.uniteam.ai/register"
                  className="btn btn-primary mrl navlink w-106 text-white"
                  onClick={() => setIsNavCollapsed(true)}
                >
                  Try it free
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
