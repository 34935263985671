import React, { useState } from "react";

/* Frequently Ask Questions */
const FrequentlyAskQues = () => {
  const [activeAccordion, setActiveAccordion] = useState("");

  const activeAccrdionFunction = (id) => {
    setActiveAccordion(activeAccordion === id ? "" : id); // Toggle accordion
  };

  const renderIcon = (id) => {
    return activeAccordion === id ? "fa-solid  fa-minus" : "fa-solid fa-plus";
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {/* <div className="card-sec text-center wow fadeIn">
                    <h3>Questions you might have before signing up</h3>
                </div> */}

        {/* <div className=" margintop-50"> */}
        <div>
          <div className="accordions">
            <div className="accordion-item">
              <div
                className={
                  activeAccordion === "accordion-item1"
                    ? "accordion-title active-title"
                    : "accordion-title"
                }
                onClick={() => activeAccrdionFunction("accordion-item1")}
              >
                <h5>
                What is Uniteam, and how can it benefit my team?{" "}
                  <i className={`fa ${renderIcon("accordion-item1")}`}></i>
                </h5>
              </div>
              <div
                className={
                  activeAccordion === "accordion-item1"
                    ? "accordion-content active"
                    : "accordion-content"
                }
              >
                <p>
                Uniteam is a simple, powerful task management tool that boosts team efficiency.
                 With task assignment, meeting integration, and AI assistance, 
                 it helps you organize, delegate, and track progress—all from one clean interface. 
                 Ensure deadlines are met and productivity soars.
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <div
                className={
                  activeAccordion === "accordion-item2"
                    ? "accordion-title active-title"
                    : "accordion-title"
                }
                onClick={() => activeAccrdionFunction("accordion-item2")}
              >
                <h5>
                How does the AI assistant in Uniteam work?{" "}
                  <i className={`fa ${renderIcon("accordion-item2")}`}></i>
                </h5>
              </div>
              <div
                className={
                  activeAccordion === "accordion-item2"
                    ? "accordion-content active"
                    : "accordion-content"
                }
              >
                <p>
                Uniteam's AI assistant simplifies your workflow with voice or text commands to create, delegate, and track tasks. Schedule meetings, check task status, or assign work effortlessly—letting you focus on what matters.
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <div
                className={
                  activeAccordion === "accordion-item3"
                    ? "accordion-title active-title"
                    : "accordion-title"
                }
                onClick={() => activeAccrdionFunction("accordion-item3")}
              >
                <h5>
                Is Uniteam secure? How is my data protected?{" "}
                  <i className={`fa ${renderIcon("accordion-item3")}`}></i>
                </h5>
              </div>
              <div
                className={
                  activeAccordion === "accordion-item3"
                    ? "accordion-content active"
                    : "accordion-content"
                }
              >
                <p>
                Security is our top priority. Uniteam, hosted on AWS, uses end-to-end encryption and regular security audits to keep your data safe. Trust that your team’s information is securely protected.
                </p>
              </div>
            </div>

            <div className="accordion-item">
              <div
                className={
                  activeAccordion === "accordion-item4"
                    ? "accordion-title active-title"
                    : "accordion-title"
                }
                onClick={() => activeAccrdionFunction("accordion-item4")}
              >
                <h5>
                How long is the free beta, and what happens after it ends?{" "}
                  <i className={`fa ${renderIcon("accordion-item4")}`}></i>
                </h5>
              </div>
              <div
                className={
                  activeAccordion === "accordion-item4"
                    ? "accordion-content active"
                    : "accordion-content"
                }
              >
                <p>
                Enjoy 3 months of free access to all Uniteam features during the beta. Explore its full potential to boost your team’s productivity. We'll notify you before the beta ends, so you can choose the best plan for your needs.
                </p>
              </div>
            </div>

            {/* Add other accordion items similarly */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrequentlyAskQues;
