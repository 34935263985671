import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Kwikbot() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Your AI Sidekick in Uniteam
                </h3>
                <p className='pai'>
                    Meet KwikBot, Uniteam’s AI-powered chatbot that makes task management and team coordination a breeze. Whether you’re looking to create tasks, assign them to teammates, schedule meetings, or get real-time updates on what your team is up to, KwikBot’s got your back. Just chat, and watch it handle the hard stuff for you.

                </p>
                <p className='pai steps'>Create Tasks with Just a Chat

                </p>
                <p className='pai'>
                    No more endless clicks—KwikBot makes task creation as easy as having a conversation. Here’s how it works:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Task Name:</b>  KwikBot will prompt you to name your task.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Assign Team Members:</b>  Tell KwikBot who should handle the task, or leave it blank to assign it to yourself automatically.


                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Set Dates: </b> KwikBot asks for start and end dates, so you don’t miss a deadline.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Priority Levels:</b>  Choose between high or normal priority—KwikBot will take care of the rest.


                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Notes & Attachments:</b>  Need to add extra details? KwikBot’s got room for notes and attachments too.

                        </p>
                    </li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/kwikbot1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='pai'>
                    With just a quick conversation, your task is live and assigned. Boom—no hassle!

                </p>
                <p className='steps'> Instant Task Status Updates
                </p>
                <p className='pai'>
                    Stay on top of everything with a simple ask. Want to know where your team stands? Just ask KwikBot:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>“What tasks are due today?”
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>“Which tasks are still pending for my team?”
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>KwikBot breaks down tasks by status, like Overdue, Today, Tomorrow, and more, so you’re always in the loop.

                        </p>
                    </li>
                    
                </ul>
                <p className='steps'>Automated Reports on the Fly

                </p>
                <p className='pai'>
                Need a quick snapshot of your team’s productivity? KwikBot’s got you:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Ask for a summary like, “What did the team finish this week?”

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Get a report on ongoing tasks, so you know who’s handling what at all times.

                        </p>
                    </li>                    
                    
                </ul>
                <p className='pai'>
                It’s like having your own AI-powered project manager, ready with answers at any time.

                </p>

               
                <p className='steps'>Team Analytics at Your Fingertips
                </p>
                <p className='pai'>
                For Admins and Team Leaders, KwikBot offers next-level insights. Want to see how your team’s performing over time? Just ask, and KwikBot will whip up a report:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Productivity Trends:</b> Track how your team’s performance changes week by week.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Engagement Levels:</b> See who’s smashing their tasks and who might need a little nudge.
                        </p>
                    </li>   
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Individual Reports:</b> Need the scoop on a specific team member? KwikBot can break it down.
                        </p>
                    </li>                  
                    
                </ul>
               
                <p className='pai'>
                No more spreadsheets—KwikBot delivers data that’s ready to act on.

                </p>
               

                <p className='steps'> Smart AI Insights </p>
                <p className='pai'>
                KwikBot doesn’t just handle tasks; it’s also your AI-powered analyst. Over time, it learns from your team’s activity and feedback to give you smarter suggestions:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Smarter Task Assignments:</b>  Based on past data, KwikBot can recommend the right person for the job.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Engagement Insights:</b> KwikBot can summarize trends from feedback surveys, highlighting what’s working and what’s not.

                        </p>
                    </li>   
                                     
                    
                </ul>
                <p className='pai'>
                KwikBot is your go-to sidekick in Uniteam, making task management smooth, efficient, and even a little fun. Let it handle the nitty-gritty while you focus on the bigger picture. Time to level up your productivity with KwikBot!

                </p>

            </div>

        </>
    );

}

export default Kwikbot;
