import React, { useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "./components/Header";
import NewFooter from "./components/newFooter";
import Carousel from "./Carousel";

const BlogsList = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("scrollTo", "");
  }, []);

  return (
    <div className="publicpage">
      <Header />
      <div className="blog-banner-container">
        <div className="blog-top-section">
          <h1 className="blog-head-1">
            {" "}
            Uniteam Insights: Elevate Your Work with
            <span> AI-Driven Solutions</span>{" "}
          </h1>
          <Carousel />
        </div>
      </div>
      <div className="blog-page">
        <div className="blog-section">
          <h2 className="mb-3">
            {" "}
            More stories just <br />
            for you{" "}
          </h2>
          <div className="row g-4">
            <div className="col-md-6 mb-40 h-100">
              <div className="card blog-card h-100">
                <img
                  src="images/blog/blog-card-1.png"
                  className="card-img-top"
                  alt="Blog Image"
                />
                <div className="blog-detail-box">
                  <div className="d-flex align-items-center">
                    <span className="badge">Technology</span>
                    <span className="text-muted small">5 min read</span>
                  </div>
                  <h5 className="card-title mt-2">
                    <Link to="/blog/5-Ways-AI-Can-Simplify-Your-Workday"> 5 Ways AI Can Simplify Your Workday
                    </Link>
                  </h5>
                
                  <span class="arrow-icon">
                    <Link to="/blog/5-Ways-AI-Can-Simplify-Your-Workday">
                      {" "}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-40 h-100">
              <div className="card blog-card h-100">
                <img
                  src="images/blog/blog-card-2.png"
                  className="card-img-top"
                  alt="Blog Image"
                />
                <div className="blog-detail-box">
                  <div className="d-flex align-items-center">
                    <span className="badge">Technology</span>
                    <span className="text-muted small">5 min read</span>
                  </div>
                  <h5 className="card-title mt-2">
                    <Link to='/blog/How-to-Keep-Your-Team-Engaged-in-a-Remote-World'>
                    How to Keep Your Team Engaged in a Remote World
                    </Link>
                  </h5>
                 
                  <span class="arrow-icon">
                  <Link to='/blog/How-to-Keep-Your-Team-Engaged-in-a-Remote-World'>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4">
            <div className="col-md-6 mb-40">
              <div className="card blog-card">
                <img
                  src="images/blog/blog-card-3.png"
                  className="card-img-top"
                  alt="Blog Image"
                />
                <div className="blog-detail-box">
                  <div className="d-flex align-items-center">
                    <span className="badge">Technology</span>
                    <span className="text-muted small">5 min read</span>
                  </div>
                  <h5 className="card-title mt-2">
                    <Link to="/blog/The-Future-of-Work:-How-AI-is-Shaping-Team-Collaboration">
                    The Future of Work: How AI is Shaping Team Collaboration
                    </Link>
                  </h5>
                 
                  <span class="arrow-icon">
                  <Link to="/blog/The-Future-of-Work:-How-AI-is-Shaping-Team-Collaboration">
                      {" "}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-40">
              <div className="card blog-card">
                <img
                  src="images/blog/blog-card-4.png"
                  className="card-img-top"
                  alt="Blog Image"
                />
                <div className="blog-detail-box">
                  <div className="d-flex align-items-center">
                    <span className="badge">Technology</span>
                    <span className="text-muted small">5 min read</span>
                  </div>
                  <h5 className="card-title mt-2">
                    <Link to='/blog/Maximizing-Efficiency-with-AI-Powered-Task-Management'>
                    Maximizing Efficiency with AI-Powered Task Management
                    </Link>
                  </h5>
                 
                  <span class="arrow-icon">
                  <Link to='/blog/Maximizing-Efficiency-with-AI-Powered-Task-Management'>
                      {" "}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-40">
              <div className="card blog-card">
                <img
                  src="images/blog/blog-card-5.png"
                  className="card-img-top"
                  alt="Blog Image"
                />
                <div className="blog-detail-box">
                  <div className="d-flex align-items-center">
                    <span className="badge">Technology</span>
                    <span className="text-muted small">5 min read</span>
                  </div>
                  <h5 className="card-title mt-2">
                    <Link to='/blog/From-Burn-Out-to-Balance:-How-AI-Can-Help-You-Work-Smarter'>
                    From Burnout to Balance: The Role of Engagement Insights in Employee Wellbeing

                    </Link>
                  </h5>
                
                  <span class="arrow-icon">
                  <Link to='/blog/From-Burn-Out-to-Balance:-How-AI-Can-Help-You-Work-Smarter'>
                      {" "}
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M2.11081 17.6273L0.244141 15.7606L13.0441 2.96061H1.57747V0.293945H17.5775V16.2939H14.9108V4.82728L2.11081 17.6273Z"
                          fill="white"
                        />
                      </svg>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default BlogsList;
