import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Users() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Adding Members to Your Organization in Uniteam</h3>
                <p className='pai'>
                    Welcome to Uniteam! Now that you’ve signed up and become the Admin of your organization, it's time to add your team members and start collaborating. Here's a simple, step-by-step guide to invite others to join your organization:

                </p>
                <p className='steps'>Step 1: Open the Settings Menu
                </p>
                <p className='pai'>
                    First, log into your Uniteam account. On the left-hand menu, you'll see a range of options. Find and click on Settings.
                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/user-3.jpg" alt="" /> 
                    </div>
                </div>
               
                        <p className='steps'>Step 2: Select "User Management"

                        </p>
                <p className='pai'>
                    Inside the Settings menu, look for User Management and click on it. This is where you’ll be able to manage all the members of your organization.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/user-1.jpg" alt="" /> 
                    </div>
                </div>
                <p className='steps'>Step 3: Click on the "Add User" Button
                        </p>
                <p className='pai'>
                In the User Management section, look at the top right corner of the screen for the + Add User button. Click on it to start inviting new members.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/user-4.jpg" alt="" />  
                    </div>
                </div>
                <p className='steps'>Step 4: Enter Email Addresses


                        </p>
                <p className='pai'>
                A pop-up window will appear. Here, you can enter the email address of the person you want to invite. You can invite multiple people at once by separating their email addresses with commas.

                </p>
               
                <p className='steps'>Step 5: Send the Invitation


                        </p>
                <p className='pai'>
                Once you’ve entered the email addresses, click the Send Invitation button. Uniteam will now send each person an email invite with a link to join your organization.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/user-1.jpg" alt="" /> 
                    </div>
                </div>

                <p className='steps'>Step 6: Wait for Your Team Members to Sign Up



                        </p>
                <p className='pai'>
                After receiving the email, each person will click the invite link and go through the signup process. They’ll create their account and automatically become a part of your organization once they’ve completed registration.

                </p>
                <p className='pai'>
                And that’s it! Now your team members can collaborate with you in Uniteam, work on tasks, and contribute to projects.

                </p>
                
            </div>
            
        </>
    );

}

export default Users;
