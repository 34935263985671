import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Onboarding() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Access the Signup Page</h3>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Head over to the Uniteam website at www.uniteam.io.</p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>In the top-right corner, click on the “Try it Free” button to get started.</p></li>
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/Onboarding-6.png" alt="" />
                    </div>
                </div>
            </div>
            <div className='mb-20'>

                <h3 className='h1ai'>Enter Your Personal Information</h3>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Fill out the sign-up form by entering your full name.</p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>Provide a valid email address to ensure smooth communication.
                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Create a strong and secure password that meets the app’s security criteria—this means at least 8 characters, including a combination of letters, numbers, and special symbols.
                    </p></li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-5.png" alt="" />
                    </div>
                </div>
            </div>
            <div className='mb-20'>

                <h3 className='h1ai'>Set Up Your Profile</h3>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Next, you’ll be prompted to provide a few additional details to complete your profile setup.
                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>Optionally, upload a profile picture to personalize your account.
                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Enter your company’s name to establish your organization’s presence on Uniteam.
                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>Add your phone number, ensuring you select the correct country code for seamless communication.

                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Choose your time zone from the dropdown menu, allowing Uniteam to sync activities to your local time.
                    </p></li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-3.png" alt="" />
                    </div>
                </div>
                <ul className='standard-ul'>
                    <br />
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>Once everything is entered, click the “Next” button to move forward.
                    </p></li>

                </ul>
            </div>
            <div className='mb-20'>

                <h3 className='h1ai'>Select Your Company’s Teams</h3>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Choose the teams that best represent the departments or groups within your company.

                    </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /><p className='pai mt-0'>Click “Next” to proceed to the next step.
                    </p></li>
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-4.png" alt="" />
                        <p>You can always add or remove teams later from your account settings if needed.</p>
                    </div>
                </div>
            </div>
            <div className='mb-20'>

                <h3 className='h1ai'>Complete the Signup Process</h3>
                <p className='pai'>
                    After selecting your teams, you’ll be directed to the Uniteam homepage, where your signup as an Admin is now complete! But don’t stop there—Uniteam is all about collaboration, so make sure to invite others to join your portal.
                </p>
            </div>
            <div className='mb-20'>
                <h3 className='h1ai'>Invite Users to Your Portal</h3>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' /> <p className='pai mt-0'>Navigate to the “Settings” option from the menu on the left.

                    </p></li>                   
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-8.png" alt="" />                    
                    </div>
                </div>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                     <p className='pai mt-0'>Select “User Management” from the menu to manage your team’s access.

                    </p></li>                   
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-7.png" alt="" />                     
                    </div>
                </div>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                     <p className='pai mt-0'>Select “User Management” from the menu to manage your team’s access.

                    </p></li>                   
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-2.png" alt="" />                    
                    </div>
                </div>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                     <p className='pai mt-0'>Enter the email addresses of those you’d like to invite to join your portal. You can enter multiple addresses by separating them with commas.


                    </p></li>                   
                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/Onboarding-1.png" alt="" />                     
                    </div>
                </div>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                     <p className='pai mt-0'>Once all email addresses are added, click the “Send Invitation” button.
The invited users will receive an email with a link to complete their own signup and start collaborating with your team.


                    </p></li>                   
                </ul>
               
            </div>
           
            <div className='mb-20'>

                <h3 className='h1ai'>Start Exploring Uniteam
                </h3>
                <p className='pai'>
                Once your signup process is complete, you’ll be taken to your dashboard, where the real work begins! From here, you can explore Uniteam’s powerful features—create and assign tasks, schedule meetings, and integrate with other essential tools to drive your team’s productivity.

                </p>
                <p className='pai'>
                We’re thrilled to have you on board and can’t wait to see how Uniteam will elevate your team’s collaboration. Let’s get started!

                </p>
            </div>
        </>
    );

}

export default Onboarding;
