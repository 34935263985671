import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Meetings() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Managing Meetings in Uniteam</h3>
                <p className='pai'>
                Scheduling and managing meetings in Uniteam is simple and designed to fit your team’s needs. Whether it's a one-time meeting or a recurring event, Uniteam has got you covered. Let’s walk through the process step-by-step.

                </p>
                <p className='steps'>Step 1: Access the Meetings Section

                        </p>
                <p className='pai'>
                To get started, head over to the Meetings section from the left-hand menu. Here, you can see your upcoming meetings or schedule a new one.

                     </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/meeting1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 2: Schedule a New Meeting


                        </p>
                <p className='pai'>
                You can schedule a meeting by:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Clicking the + button in the top right corner, or

                        </p></li>
                        <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Clicking the Schedule Meeting button.


                        </p></li>

                </ul>
               
                <p className='steps'>Step 3: Fill in Meeting Details

                        </p>
                <p className='pai'>
                Now, you’ll see a form where you can enter the details of the meeting. Here’s what you’ll need to do:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Meeting Title:</b> Give your meeting a descriptive and clear name.
                        
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Meeting Note:</b> Add any relevant notes about the meeting.
                       
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Pick a Date: </b>Choose the date for the meeting.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Recurring Meetings:</b> If this meeting will happen regularly, click Recurring. In the popup, choose how often you want the meeting to repeat—Daily, Weekly, or Monthly—and set the date under Repeat Until to specify when the recurring meetings should stop. Click Save once done.
                       
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'><b>Start and End Time:</b> Select when the meeting will begin and end.
                        </p>
                    </li>
                   
                </ul>
               
                <div className='mb-20'>
                <p className='steps'>Step 4: Select the Meeting Type

                        </p>
                <p className='pai'>
                Next, choose how the meeting will be held:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'>
                        <p className='pai mt-0'><b>Physical:</b> For in-person meetings. Enter the venue details (e.g., office address or meeting room).

                        
                        </p>
                    </li>
                    <li className='ulli'>
                        <p className='pai mt-0'><b>Virtual:</b> For online meetings. Pick the platform you’ll use (e.g., Google Meet, Zoom, Teams, or others).

                       
                        </p>
                    </li>
                    
                   
                </ul>

                </div>
                
              

                <p className='steps'>Step 5: Add Attachments
                        </p>
                <p className='pai'>
                If you need to share files before or during the meeting, click on Attachment to upload the necessary documents or files.

                </p>

                <p className='steps'>Step 6: Set the Meeting Organizer

                        </p>
                <p className='pai'>
                Under Meeting Called By, select the person who will head or lead the meeting. This is the main point of contact and the person responsible for the meeting.

                </p>
                <p className='steps'>Step 7: Invite Participants
                        </p>
                <p className='pai'>
               Finally, choose the people who will be invited to the meeting. You can search for users by name or pick them from the list. These invitees will receive the meeting invite.
               </p>
               <p className='steps'>Step 8: Save the Meeting


                        </p>
                <p className='pai'>
                Once you’ve entered all the details, click Schedule to finalize and schedule the meeting. Your invitees will automatically be notified, and the meeting will be added to your upcoming meetings list.

                </p>

                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/meeting2.png" alt="Hero Image" />
                    </div>
                </div>

                <p className='steps'>Using KwikBot for Meetings:
                        </p>

                <p className='pai'>
                Don’t want to go through the manual steps? KwikBot, Uniteam’s AI-powered assistant, is here to help! Simply tell KwikBot to schedule a meeting, provide the necessary details (like title, date, and participants), and KwikBot will handle the rest for you.

                </p>
                <p className='pai'>
                You can also use KwikBot to modify existing meetings. Just ask it to update the details of an upcoming meeting, and it’ll take care of everything in seconds—no hassle!

                </p>

                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/meeting3.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Additional Features:
                </p>

                <p className='steps'>
                Edit a Meeting </p>

                <p className='pai'>
                Need to change something after the meeting is scheduled? No worries! Simply click on the meeting title from the meetings dashboard, and you’ll be able to edit any details like the time, participants, or venue. Once you’ve made the changes, save the updated meeting.

                </p>
                <p className='steps'>
                Calendar View
                </p>
                <p className='pai'>
                Want a more visual way to view your meetings? Just like with tasks, you can switch to the Calendar View in the Meetings section. Click on the Calendar Icon at the top of the meetings dashboard, and all your upcoming meetings will be laid out in a calendar format, making it easy to see what’s coming up and plan ahead.

                </p>
                <p className='pai'>
                With Uniteam, managing meetings—whether one-off or recurring—has never been more flexible. You can edit meetings, view them in a calendar format, and customize them to fit your team’s needs effortlessly!

                </p>
                <p className='pai'>
                With Uniteam, organizing and managing meetings has never been easier. From setting up recurring meetings to choosing between physical and virtual options, everything you need is at your fingertips. Happy scheduling!


                </p>

                
            </div>
            
        </>
    );

}

export default Meetings;
