import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewFooter from '../components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";


const FeaturePerformanceAnalytics = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features  </Link>
                            <i className="fas fa-chevron-right" style={{
                                fontSize: '12px',
                            }}></i>{" "}
                            <spna>Performance Analytics</spna>
                        </div>
                        <h1 className="">Real-Time Analytics – Actionable Insights for Teams</h1>
                        <p className="mb-4">
                        Uniteam’s real-time analytics dashboards give you a clear view of your team’s efficiency and engagement, with AI-powered insights that help you take action.
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register"  className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/contact-us" target='_blank' className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                    <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Efficiency Dashboard</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Engagement Dashboard</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>AI-Powered Insights</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Employee Feedback Summary</span>
                            </div>

                        </div>
                       
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Efficiency Dashboard</h2>
                            <p>
                            Track task completion rates, identify productivity trends, and uncover areas for improvement. Uniteam’s efficiency dashboard provides key insights into how well your team is performing, helping you drive results.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-efficiency-dashboard.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Engagement Dashboard</h2>
                            <p>
                            Stay ahead of potential morale issues with real-time engagement tracking. Uniteam measures engagement levels, identifies key drivers, and provides actionable tips to keep your team motivated and reduce turnover
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-engagement-dashboard.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>AI-Powered Insights</h2>
                            <p>
                            Uniteam’s proprietary algorithms analyze your team’s performance and provide actionable recommendations. These insights help managers take proactive steps to improve both productivity and engagement.
                            </p>
                        </div>
                        
                    </div>

                </section>
               
              
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="/images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                        </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>
            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default FeaturePerformanceAnalytics;