import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Integrations() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>How to Set Up Your Integrations in Uniteam?</h3>
                <p className='pai'>
                    Uniteam makes connecting your favorite meeting platforms and calendars a breeze! Whether you’re team Google Meet, a Zoom master, or a Microsoft Teams pro, we’ve got you covered. Here’s how to set up these integrations in just a few simple steps:

                </p>
                <p className='steps'>Step 1: Navigate to Your Profile
                        </p>
                <ul className='standard-ul'>
                    <li className='ulli'>
                        <p className='pai mt-0'><b>Click on your Profile</b> Picture at the top right corner of your Uniteam dashboard.
                            A dropdown will appear.


                        </p>
                    </li>
                    <li className='ulli'>
                        <p className='pai mt-0'><b>Select “Manage Your Account”</b> from the dropdown. You’ll be taken to your settings page where all the magic happens.


                        </p>
                    </li>


                </ul>

                <p className='steps'>Step 2: Find Integrations

                        </p>
                <p className='pai'>
                    <b>Look to the left menu </b>for the “Integrations” option. It’s your gateway to connecting all your essential tools.

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Click on Integrations and a list of supported platforms will appear.


                        </p></li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/integration1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 3: Connect Your Favorite Tools
                        </p>
                <p className='pai'>
                    Here’s where the fun begins! You can integrate:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Google Meet
                        </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Zoom
                        </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Microsoft Teams

                        </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Google Calendar

                        </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>iCal

                        </p></li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Microsoft Calendar
                        </p></li>

                </ul>
                <p className='pai'>
                Simply follow these steps to get them connected:
                </p>
                
                <p className='pai'>
                <b>Click “Connect” </b>next to the tool you want to integrate (Zoom, Google Calendar, etc.).
                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/integration2.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='pai'>
                <b>Authenticate </b> A window will pop up prompting you to log in to your account for that service (e.g., Google, Zoom, Microsoft).
                </p>

                <p className='pai'>
                <b>Grant Permissions: </b> Give Uniteam the necessary permissions to sync your meetings and calendars.

                </p>

                <p className='pai'>
                <b>Success!</b>You’re all set! Your meetings and events will now sync seamlessly with Uniteam.
                
                </p>

                
                <p className='pai'>
                That's it! Integrating your platforms with Uniteam couldn’t be easier.

                </p>
                

                

            </div>

        </>
    );

}

export default Integrations;
