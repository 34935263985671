import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function Analytics() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Organization Level Analytics</h3>
                <p className='pai'>
                    As an Organization or Company Admin, you have access to organizational-level analytics to gain insights into overall productivity and performance. Here’s how to get started:

                </p>

                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/analytics1.png" alt="Hero Image" />
                    </div>
                </div>

                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Accessing Organizational Level Analytics:</b> Select the All option from the team dropdown to view insights at the organizational level.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> View Insights:</b> By default, you’ll see analytics for the <b>last 30 days</b>, but you can easily change the time period using the following options:


                        </p>


                    </li>
                    <p >
                        <ul className='inner-ul'>
                            <li>Last 30 days </li>
                            <li>This Month </li>
                            <li>Last Month  </li>
                            <li>This Quarter </li>
                            <li>Last Quarter </li>
                            <li>This Year </li>
                            <li>Last Year</li>
                        </ul>
                    </p>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Team Level Analytics:</b> To explore performance by specific teams, simply select the desired team from the dropdown.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Task Breakdown:</b> Get a clearer picture of task assignments by selecting the task type from the dropdown. By default, the All Tasks option will be selected, showing insights on both self-assigned and delegated tasks.

                        </p>
                    </li>

                </ul>


                <p className='steps'>Overall Task Completion
                </p>
                <p className='pai'>
                    This widget offers a quick glance at overall task completion for the selected period. Here’s what you’ll find:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Total Scheduled Tasks:</b> View the total number of tasks scheduled during your selected timeframe.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Total Completed Tasks: </b> Check how many tasks have been successfully completed.
                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Completion Percentage:</b> This shows the ratio of completed tasks to scheduled tasks, giving you a sense of efficiency.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> Growth Comparison: </b> Compare the completion percentage of the selected period with the previous one to see if your team is improving or facing challenges.

                        </p>
                    </li>

                </ul>

                <p className='steps'>Overall Task Completion
                </p>
                <p className='pai'>
                    Stay updated on task completion trends with this intuitive line chart widget:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Visual Insights: </b>View the total completed tasks and overdue tasks for each date in the selected period.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Color-Coded Lines: </b>The chart uses a green line for completed tasks and a red line for overdue tasks, making it easy to spot trends at a glance.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b> Dynamic X-Axis:</b> The X-axis will adjust based on your selected time period, helping you analyze trends over time.

                        </p>
                    </li>


                </ul>

                <p className='steps'>Overdue Tasks</p>
                <p className='pai'>
                    Identify overdue tasks efficiently with this widget, which categorizes them based on how long they've been overdue:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Up to 7 Days: </b> Tasks that are overdue for less than a week.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>7 to 15 Days:</b> Tasks overdue between 7 to 15 days.
                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>15 to 30 Days:</b>  Tasks overdue for 15 to 30 days.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>More than 30 Days: </b>Tasks overdue for over a month.
                        </p>
                    </li>

                </ul>
                <p className='steps'>Team Performance</p>
                <p className='pai'>
                    Monitor the performance of your teams with this insightful widget:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Team Overview:</b> View each team’s name alongside key performance metrics.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> Task Completion Rate: </b>This percentage shows how many tasks were completed relative to the total assigned.
                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Punctuality Score:</b> Get a sense of how many tasks were completed on time.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Visual Representation: </b> The performance section features a stacked bar chart, illustrating:

                        </p>
                    </li>
                    <ul className='inner-ul'>
                        <li>Total tasks completed on time.
                        </li>
                        <li>Total delayed tasks. </li>
                        <li>Total tasks that are overdue.
                        </li>

                    </ul>

                </ul>
                <p className='pai'>
                    To delve deeper into a team's performance, simply click on the team name!

                </p>
            </div>

            <div className='mb-20'>


                <h3 className='h1ai'>Team Level Analytics</h3>
                <p className='pai'>
                    In Uniteam, Team Level Analytics offers a detailed overview of how teams are performing, helping Organization/Company Admins and Team Leaders to make data-driven decisions. Here's how you can make the most of this feature:

                </p>

                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/analytics2.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>
                    Accessing Team Level Analytics

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> Who can view this?</b> Organization/Company Admins and Team Leaders.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> How to access it? </b>Simply select your desired team from the team dropdown. By default, the "All Tasks" option is selected, but you can also filter for specific task types such as self-assigned or delegated tasks.
                        </p>

                    </li>


                </ul>


                <p className='steps'>Overall Task Completion Widget

                </p>
                <p className='pai'>
                    This widget gives you an easy-to-understand overview of how your team is progressing over a selected time period.

                </p>
                <p className='steps'>What does it show?</p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Total Scheduled Tasks: </b>Number of tasks planned for the selected period.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Total Completed Tasks:  </b>Number of tasks finished within the selected period.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Completion Percentage:</b> Calculated as (Completed Tasks / Scheduled Tasks) * 100.


                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> Growth Comparison:</b> Shows how task completion has improved or declined compared to the previous period:

                        </p>
                    </li>
                    <ul className='inner-ul'>
                        <li>Completion percentage for the current period.
                        </li>
                        <li>Completion percentage for the last period. </li>
                        <li>Growth is calculated by comparing A1 and A2. If A1 > A2, there's positive growth; otherwise, growth is declining.
                        </li>
                    </ul>

                </ul>

                <p className='steps'>Task Completion Trend Widget
                </p>
                <p className='pai'>
                    Stay on top of your team's productivity by analyzing trends in task completion over time.

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Key insights: </b>
                        </p>
                    </li>
                   
                    <ul className='inner-ul'>
                        <li><b>Total Completed Tasks:</b> For each date in the selected period.
                        </li>
                        <li><b>Total Overdue Tasks:</b> Tasks not completed by their due date.
                        </li>
                    </ul>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Visual Aid:</b>
                        </p>
                        <p className='ml-20px'> </p>
                    </li>
                    <ul className='inner-ul'>
                        <li>A green line represents completed tasks, and a red line shows overdue tasks on a line graph.
                       
                        </li>
                        
                    </ul>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b> Customizable Timeframes:</b>
                        </p>
                        
                    </li>
                    <ul className='inner-ul'>
                        <li>View trends for the last 30 days, current/last month, quarter, or year. The timeline adjusts accordingly to show the time periods (e.g., days, weeks, or months).

                        </li>
                        
                    </ul>


                </ul>

                <p className='steps'>Overdue Tasks Widget </p>
                <p className='pai'>
                    Quickly identify tasks that have missed their deadlines. This widget breaks down overdue tasks into four categories:
                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Up to 7 Days: </b> Tasks overdue by a week or less.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>7 to 15 Days:</b> Tasks overdue by 7–15 days.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>15 to 30 Days:</b>  Tasks overdue by 15–30 days.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>More than 30 Days: </b>Tasks overdue for more than a month.

                        </p>
                    </li>

                </ul>
                <p className='steps'>User Performance Widget </p>
                <p className='pai'>
                    This widget lets you dive deeper into the performance of each individual team member.

                </p>
                <p className='steps'>Key information displayed</p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Task Completion Rate: </b> Percentage of tasks completed by the user, calculated as (Tasks Completed / Total Tasks Assigned) * 100.


                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Punctuality Score:</b> Measures how timely users are with their tasks, calculated as (On-Time Completed Tasks / Total Completed Tasks) * 100.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Task Breakdown:</b> A stacked bar chart showing:


                        </p>
                    </li>
                    <ul className='inner-ul'>
                        <li><b>Tasks completed on time. </b>
                        </li>
                        <li><b>Tasks delayed.</b>
                        </li>
                        <li><b>Overdue tasks.  </b>
                        </li>
                    </ul>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            By reviewing this, Admins and Team Leaders can easily identify strong performers and areas needing improvement.


                        </p>
                    </li>
                </ul>
                <p className='pai'>
                    Uniteam’s Team Level Analytics provides a clear and visual understanding of how each team and its members are performing. By reviewing productivity trends, task completion rates, and individual user performance, you can ensure that your teams are on track and improve overall efficiency.
                </p>
            </div>

            <div className='mb-20'>


                <h3 className='h1ai'>Engagement Analytics </h3>
                <p className='pai'>
                    The Engagement Analytics Dashboard in Uniteam provides Organization/Company Admins with key insights into employee happiness and engagement levels. This data-driven approach helps organizations foster a positive work environment and maintain high levels of employee satisfaction.
                </p>

                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/analytics3.jpg" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Engagement Meter
                </p>
                <p className='pai'>
                    The Engagement Meter provides an at-a-glance view of how happy and engaged employees are on any given day.

                </p>

                <p className='pai'>
                    Today’s Happiness Score
                </p>
                <p className='pai'>
                    This widget displays Today’s <b>Happiness Score</b>—a percentage that represents how happy employees feel today.

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            The score is derived from a happiness survey completed by users, with responses ranging from "Very Unhappy" to "Very Happy."
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            The survey uses emoji-based responses, with each emoji assigned a numeric value:
                        </p>
                    </li>
                    <p >
                        <ul className='inner-ul'>
                            <li>Very Unhappy = 1
                            </li>
                            <li>Unhappy = 2
                            </li>
                            <li>Neutral = 3
                            </li>
                            <li>Happy = 4
                            </li>
                            <li>Very Happy = 5
                            </li>

                        </ul>
                    </p>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            The happiness score is calculated based on these responses and then converted into a percentage to give you a clear picture of your team’s mood.

                        </p>
                    </li>

                </ul>

                <p className='pai'>
                    <b>Example:</b> If 80 out of 100 users submitted their survey today and the weighted score is calculated to be 3.5, this is then translated into a percentage happiness score, say 89%, to reflect today’s overall team sentiment.
                </p>
                <p className='pai'>
                    Average Engagement Score (Last 90 Days)

                </p>

                <p className='pai'>
                    This widget highlights the Average Engagement Score over the last 90 days. It provides a broader view of engagement trends within the organization, helping Admins understand how employees have been feeling over an extended period.
                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            The score is based on the <b>engagement survey</b> responses, where users rate various aspects of their engagement on a scale of 1 to 5.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            The Average Engagement Score is derived by calculating the weighted average for each question over the last 90 days and then converting the score into a percentage.
                        </p>
                    </li>

                </ul>
                <p className='pai'>
                    <b>Example:</b> If the average score of responses for the last 90 days is calculated to be 80.56%, this means that over the past three months, the team’s engagement has been generally high.

                </p>
                <p className='steps'>Key Takeaways:

                </p>

                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Engagement Meter</b> provides both daily and long-term insights, helping you to track and understand fluctuations in happiness and engagement.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            These insights are based on direct feedback from employees, making them a valuable tool for improving workplace culture and employee satisfaction.

                        </p>
                    </li>

                </ul>


                <p className='pai'>
                    Uniteam’s Engagement Analytics is a powerful tool to measure employee sentiment and engagement over time, allowing organizations to take timely actions to maintain a positive and productive work environment.

                </p>
                <p className='pai'>
                    The <b>Engagement Meter</b> in Uniteam goes beyond just showing today's engagement levels. It allows Admins to track trends and drill down into specific engagement drivers over time. With this tool, Admins can gain deeper insights into how employee engagement evolves and understand the factors driving these trends.

                </p>
                <p className='steps'>Engagement Score Trend</p>
                <p className='pai'>
                    This widget presents a <b>line graph</b> that tracks engagement trends over the past 90 days.

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        Two lines are displayed, offering a year-over-year comparison:
                        </p>
                    </li>
                    <ul className='inner-ul'>
                            <li>One line represents the <b>current year’s engagement trends.</b>
                            </li>
                            <li>The second line reflects trends from the <b>previous year.</b>

                            </li>                            
                        </ul>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        This allows Admins to analyze how engagement levels this year stack up against the same period last year.

                        </p>
                    </li>


                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                        The graph helps visualize fluctuations in engagement and can highlight patterns or recurring dips in engagement levels.

                        </p>
                    </li>


                </ul>

                <p className='steps'>Filters for Customized Insights</p>
                <p className='pai'>
                Admins can narrow down engagement data using filters to get more targeted insights. The available filters include:

                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b> Gender: </b> Male or Female

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                            <b>Location: </b>Admins can use a city-based filter powered by Google Places API to see how engagement varies across different locations.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Age Group:</b> Categories such as 20-30 years, 30-40 years, and above 40 years can be selected to filter data based on age demographics.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                            <b>Length of Service: </b> Insights can be filtered based on how long employees have been with the organization:

                        </p>
                    </li>
                    <ul className='inner-ul'>
                        <li>0-1 Year
                        </li>
                        <li>1-3 Years
                        </li>
                        <li>3-5 Years
                        </li>
                        <li>5-10 Years
                        </li>
                        <li>Over 10 Years
                        </li>

                    </ul>


                </ul>
                <p className='pai'>
                These filters make it easy to dive deeper into the data and understand how various groups within the organization are engaging with their work.

                </p>
                <p className='steps'>Detailed Score: Engagement Drivers
                </p>
                <p className='pai'>
                This section breaks down engagement data by engagement drivers—specific factors that influence employee engagement. Each engagement driver is calculated based on user feedback from the past 90 days.
                </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                           A <b>donut graph</b> displays the average engagement score for each driver in the current period (last 90 days).

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        Growth or decline in engagement is tracked by comparing the current period to the previous period, providing a visual indication of how specific drivers have improved or declined.
                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                        Clicking on an <b>Engagement Driver</b> will show a detailed report for that specific driver.


                        </p>
                    </li>                    

                </ul>

                <p className='steps'>Driver Details Report

                </p>
                <p className='pai'>
                This report offers a focused look at engagement within a particular driver.
               
               </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        A line graph compares engagement trends over the past 90 days, similar to the overall trend graph, but specifically for the selected driver.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        All survey questions mapped to the engagement driver are listed, showing average scores for rating-based questions.

                        </p>
                    </li>

                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>

                        For text-based questions, responses are compiled and summarized by AI, offering a clear and concise view of the feedback provided.


                        </p>
                    </li>                    

                </ul>
                <p className='steps'>AI-Generated Insights
                </p>
                <p className='pai'>
                This section offers a powerful feature: AI-Generated Reports. By analyzing user responses from the last 6 months, AI compiles insights and generates a report that captures the overall state of engagement across the organization.

               </p>
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        AI processes both quantitative and qualitative responses to provide a holistic view of employee sentiment.

                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>
                        The AI-generated report helps organizations make informed decisions by summarizing key trends and highlighting areas for improvement.

                        </p>
                    </li>

                   
                </ul>
                <p className='pai'>
                With Uniteam's Engagement Meter, Admins not only get a snapshot of how employees feel today but also gain access to historical data, filtered insights, and AI-driven reports that make it easier to keep a finger on the pulse of employee engagement.

                </p>
                <p className='pai'>
                With these analytics tools at your disposal, you can foster a productive and engaged work environment. Happy analyzing!

                </p>
            </div>

        </>
    );

}

export default Analytics;
