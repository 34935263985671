import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import NewFooter from '../components/newFooter';
import { Link } from 'react-router-dom';

function ManageYourAccount() {


    return (
        <>
            <div className='mb-20'>


                <h3 className='h1ai'>Manage Your Account in Uniteam</h3>
                <p className='pai'>
                Managing your account in Uniteam is super easy, and you’re always in control of your personal details. Let’s walk through how to update your information step by step!

                </p>
                <p className='steps'>Step 1: Access the Manage Your Account Section
                        </p>
                <p className='pai'>
                First, click on your profile picture in the top right corner of the screen. A dropdown menu will appear—just click on Manage Your Account to get started.

                   </p>
                
                   <p className='steps'>Step 2: Open Profile Settings


                        </p>
                <p className='pai'>
                On the left side of your screen, you’ll see a menu. Click on Profile Settings—this is where all your personal info can be updated.

                </p>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                        <img src="/images/common/manageyouracount1.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='steps'>Step 3: Update Your Details

                        </p>
                <p className='pai'>
                Now, here’s the fun part! You can update the following:

                </p>
               
                <ul className='standard-ul'>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Name: Change your display name if needed. </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Designation: Update your job title or role.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Gender: Set or update your gender.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Location: Add or change your location details. </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Date of Birth: Update your birthdate to keep things accurate.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Phone Number: Make sure your contact number is up to date, and don’t forget to pick the right country code.
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Time Zone: Keep your time zone in sync, especially if you’re traveling!
                        </p>
                    </li>
                    <li className='ulli'><img src='/images/icons/starburst.svg' />
                        <p className='pai mt-0'>Password: Feel like changing your password? You can do that here too for added security.
                        </p>
                    </li>

                </ul>
                <div className="row mt-40">
                    <div className="col-md-12 help-center-img">
                    <img src="/images/common/manageyouracount2.png" alt="Hero Image" />
                    </div>
                </div>
                <p className='pai'>
                And that’s it! Keeping your account info updated is quick and simple. You’re always just a few clicks away from making any changes you need.

                </p>
               
                
                
            </div>
            
        </>
    );

}

export default ManageYourAccount;
