import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer, toast, Zoom  } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.css';
import MyProvider from './contexts/MyProvider';
import AboutUs from './publicpages/AboutUs';
import Support from './publicpages/Support';
import Features from './publicpages/Features';
import BlogsList from './publicpages/BlogsList';
import BlogDetails from './publicpages/BlogDetails5ways';
import BlogDetails2 from './publicpages/BlogDetailsRemoteWorld';
import BlogDetails3 from './publicpages/BlogDetailsFutureWorld';
import BlogDetails4 from './publicpages/BlogDetailsMaximizingEfficiency';
import BlogDetails5 from './publicpages/BlogDetailsBurnOutTOBalance';
import BlogDetails6 from './publicpages/BlogDetailsAiAssistants';
import BlogDetails7 from './publicpages/BlogDetailsDataBoosting';
import BlogDetails8 from './publicpages/BlogDetailsActionableTips';

import HelpCenter from './publicpages/help-center';
import HelpCenterDetails from './publicpages/helpcenter/help-center-details';
import ContactUs from './publicpages/contact-us';

import FeatureTeam from './publicpages/features/FeatureTeam';
import FeatureTask from './publicpages/features/FeatureTask';
import FeatureAIAssistant from './publicpages/features/FeatureAIAssistant';
import FeatureMeeting from './publicpages/features/FeatureMeeting';
import FeatureProject from './publicpages/features/FeatureProject';
import FeaturePerformanceAnalytics from './publicpages/features/FeaturePerformanceAnalytics';
import FeatureEngagementAnalytics from './publicpages/features/FeatureEngagementAnalytics';

import WatchTutorials from './publicpages/WatchTutorials';
import HelpGuide from './publicpages/HelpGuide';
import BookADemo from './publicpages/BookADemo';


// Public Pages
const Home = lazy(() => import('./publicpages/Home'));
const TermCondition = lazy(() => import('./publicpages/Term&Condition'));
const TermsOfUse = lazy(() => import('./publicpages/TermsOfUse'));
const Privacypolicy = lazy(() => import('./publicpages/Privacypolicy'));
const Documentation = lazy(() => import('./publicpages/Documentation'));
const NotFound = lazy(() => import('./publicpages/error'));

// const MsTeamMeeting = lazy(() => import('./components/meeting/MsTeam/MsTeamMeeting'));


function App() {
  console.log("updated on : 15 nov 2024 6:47pm");
  return (
    <MyProvider>
      <BrowserRouter>
        <ToastContainer 
        position="top-center" 
        autoClose={5000} 
        hideProgressBar={true} 
        newestOnTop={false} 
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom} // You can also use Slide, Bounce, etc.
        style={{ top: "50%", transform: "translateY(-50%)" }}/>

        <Suspense fallback={<div></div>}>
          <Routes>
            {/* <Route path="/signalr" element={<SignalRComponent clientId="6" userId="534" />} /> */}
            <Route path="/" element={<Home />} />

            <Route path="/terms-and-conditions" element={<TermCondition />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacypolicy" element={<Privacypolicy />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/support" element={<Support />} />
            <Route path="/features" element={<Features />} />
            <Route path="/blogs" element={<BlogsList />} />
            <Route path="/book-a-demo" element={<BookADemo />} />

            <Route path="/blog/5-Ways-AI-Can-Simplify-Your-Workday" element={<BlogDetails />} />
            <Route path="/blog/How-to-Keep-Your-Team-Engaged-in-a-Remote-World" element={<BlogDetails2/>} />
            <Route path="/blog/The-Future-of-Work:-How-AI-is-Shaping-Team-Collaboration" element={<BlogDetails3/>} />
            <Route path="/blog/Maximizing-Efficiency-with-AI-Powered-Task-Management" element={<BlogDetails4/>} />
            <Route path="/blog/From-Burn-Out-to-Balance:-How-AI-Can-Help-You-Work-Smarter" element={<BlogDetails5/>} />
            <Route path="/blog/productivity-task-management" element={<BlogDetails6/>} />          
            <Route path="/blog/Actionable-Tips-for-Managing" element={<BlogDetails8/>} />
            <Route path="/blog/employee-engagemente-culture" element={<BlogDetails7/>} />
            <Route path="/help-center" element={<HelpCenter />} />
            <Route path='/helpcenter/details' element={<HelpCenterDetails/>} />
            <Route path="/contact-us" element={<ContactUs />} />

            
            <Route path="/feature/team" element={<FeatureTeam />} />
            <Route path="/feature/task" element={<FeatureTask />} />
            <Route path="/feature/ai-assistant" element={<FeatureAIAssistant />} />
            <Route path="/feature/meeting" element={<FeatureMeeting />} />
            <Route path="/feature/project" element={<FeatureProject />} />
            <Route path="/feature/engagement-analytics" element={<FeatureEngagementAnalytics />} />
            <Route path="/feature/performance-analytics" element={<FeaturePerformanceAnalytics />} />

            <Route path="/watch-tutorials" element={<WatchTutorials />} />
            <Route path="/help-guide" element={<HelpGuide />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </MyProvider>
  );
}

export default App;
