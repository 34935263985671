import React, { useEffect } from 'react'
import ContactUsSection from '../components/ContactUsSection'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NewFooter from '../components/newFooter'
import { Link, useNavigate, useLocation } from "react-router-dom";

const FeatureMeeting = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="publicpage">

            <Header />
            <div className='features-page'>
                <div className="header-container">
                    <div className="">
                        <div className='breadcrumb-container'>
                            <Link to="/">Features  </Link>
                            <i className="fas fa-chevron-right" style={{
                                fontSize: '12px',
                            }}></i>{" "}
                            <spna>Meeting</spna>
                        </div>
                        <h1 className="">Effective Meetings, Clear Outcomes</h1>
                        <p className="mb-4">
                        Uniteam brings structure and accountability to your meetings, making it easy to track action items and ensure follow-ups are completed
                        </p>
                        <div className='features-page-btn-container'>
                        <Link to="https://app.uniteam.ai/register"  className="btn  get-started">
                                Get Started
                            </Link>
                            <Link to="/contact-us" target='_blank' className="btn book-demo">
                                Book a Demo
                            </Link>
                        </div>

                    </div>
                    <div className='help-you'>
                        <p>Here's how we can help you</p>
                    </div>
                </div>
                {/* Features Section */}
                <section className="innercontainer feature-container">

                    <div className="row">
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Create and Track Meetings</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Integrations with Popular Tools</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Action Item Tracking</span>
                            </div>

                        </div>
                        <div className="col-md-4">
                            <div className=' feature-item'>
                                <img src="/images/icons/right-icon-feature.png" alt="Icon 1" />
                                <span>Meeting History</span>
                            </div>

                        </div>
                       
                    </div>

                </section>
                {/* Section: All Tasks in One View */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Create and Track Meetings</h2>
                            <p>
                            Schedule meetings, send invites, and track action items all from within Uniteam. Meeting invites and action items seamlessly integrate with your task list, keeping everything in one place
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-create-and-track.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Effortless Task Creation */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Integrations with Popular Tools</h2>
                            <p>
                            Uniteam works with the tools you already use. Schedule virtual meetings with Zoom, MS Teams, and Google Meet directly from the platform. It also integrates with popular calendar systems to ensure everything stays synced.
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-popular-tols.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Team Leader Advantages */}
                <section className="innercontainer mt-100">

                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <h2>Action Item Tracking</h2>
                            <p>
                            Once a meeting ends, action items automatically appear on the responsible team members’ task lists, so there’s no confusion about next steps. Track progress, check accountability, and see what’s been accomplished with ease.
                             </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-action-team.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
                {/* Section: Never Miss a Beat */}
                <section className="innercontainer bg-light mt-100">

                    <div className="row align-items-center">

                        <div className="col-md-12">
                            <h2>Meeting History</h2>
                            <p>
                            Access past meetings anytime. Review action items, check which tasks have been completed, and maintain accountability across your team
                            </p>
                        </div>
                        <div className="col-md-12">
                            <img src="/images/features/feacture-meeting-history.png"
                                className="img-fluid img-fetmt"
                                alt="All tasks view"
                            />
                        </div>
                    </div>

                </section>
              
              
                <section className="testimonial-section innercontainer mt-100">
                    {/* Company Logo */}
                    <div className="company-logo">
                        <img
                            src="/images/features/Amazon_logo.svg"
                            alt="Amazon Logo" 
                        />
                    </div>
                    {/* Testimonial Text */}
                    <div className="testimonial-text">
                        <p>
                        “Uniteam has revolutionized our task management process. The AI features
                            are incredibly helpful! Synthium has revolutionized our task management
                            process. The AI features are incredibly helpful!”
                        </p>
                    </div>
                    {/* Author Info */}
                    <div className="author-info">
                        {/* Author Image */}
                        <div className="author-img">
                            <img src="/images/features/sample-profile.png" alt="Jonathan Doe" />
                        </div>
                        {/* Author Details */}
                        <div className="author-details">
                            <h4>Jonathan Doe</h4>
                            <span>Design Director, Amazon</span>
                        </div>
                    </div>
                    </section>
            </div>
            {/* <Footer /> */}
            <NewFooter />
        </div>
    )
}

export default FeatureMeeting;