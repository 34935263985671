import React, { useState, useEffect, startTransition } from "react";
import {  useNavigate } from "react-router-dom";


const ip = "59.59.59.59";

function BookADemo() {
    const navigate = useNavigate();
 
  return (
    <div>
     
        <div className="onboarding login">
          <div className="d-flex justify-content-between onboarding-top-header align-items-center">
            <div className="logo mt-0">
              <a className="navbar-brand mt-0 pt-0" onClick={() => navigate("/")}>
                <img
                  alt="image"                 
                  src="images/icons-v2/uniteam-Logo-white-new.svg"
                 width="150px"                
                />                
              </a>
            </div>
           
          </div>
        
         <iframe src="https://calendly.com/uniteamai/30min?month=2024-11?embed_domain=uniteam.ai/&amp;embed_type=Inline" width="100%" height="100%" frameborder="0" title="Select a Date &amp; Time - Calendly"></iframe>
         
         
          <div className="clearfix"></div>
        </div>
  
    </div>
  );
}

export default BookADemo;
